// eslint-disable-next-line no-unused-vars
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "./navigation"
import Footer from "./footer/footer"

const Layout = ({
  children,
  isDarkMode,
  langKey = "en",
  allContentfulFooter,
}) => {
  const data = useStaticQuery(graphql`
    query SeelkLogoQuery {
      allContentfulSeelkLogo {
        edges {
          node {
            seelkDarkLogo {
              fluid(maxWidth: 230) {
                ...GatsbyContentfulFluid
              }
            }
            seelkLightLogo {
              fluid(maxWidth: 230) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const seelkLogos = data.allContentfulSeelkLogo.edges[0].node
  const seelkLogo = isDarkMode
    ? seelkLogos.seelkLightLogo.fluid
    : seelkLogos.seelkDarkLogo.fluid

  return (
    <div sx={{ overflow: `hidden` }}>
      <Navigation
        langKey={langKey}
        isDarkMode={isDarkMode}
        seelkLogo={seelkLogo}
        seelkDarkLogo={seelkLogos.seelkDarkLogo.fluid}
      />
      <main>{children}</main>
      <Footer
        langKey={langKey}
        isDarkMode={isDarkMode}
        seelkDarkLogo={seelkLogos.seelkDarkLogo.fluid}
        allContentfulFooter={allContentfulFooter}
      />
    </div>
  )
}

export default Layout
