// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { colors } from "../../../../utils/theme/colors"

import FooterColumn from "./shared/FooterColumn"
import FooterBoldText from "./shared/FooterBoldText"
import FooterRegularText from "./shared/FooterRegularText"
import NavigationLink from "../../../common/NavigationLink"

const SeelkOffices = ({ textColor, seelkOffices, langKey, contact }) => {
  const data = useStaticQuery(graphql`
    query ContactInfoQuery {
      phone: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fixed(width: 14, height: 15) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      address: file(relativePath: { eq: "adress.png" }) {
        childImageSharp {
          fixed(width: 12, height: 15) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const address = data.address.childImageSharp.fixed
  const phone = data.phone.childImageSharp.fixed

  return (
    <FooterColumn sx={{ width: "100%" }}>
      {seelkOffices.map((seelkOffice, i) => {
        const { officeCity, officeAddress, officeTelephoneNumber } = seelkOffice

        return (
          <div
            key={i}
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: "32px",
            }}
          >
            <FooterBoldText textColor={textColor}>
              Seelk {officeCity}
            </FooterBoldText>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "16px",
              }}
            >
              <Img alt="" fixed={address} sx={{ mr: "16px" }} />
              <FooterRegularText
                textColor={textColor}
                overrideCSS={{ mb: "0px" }}
              >
                {officeAddress}
              </FooterRegularText>
            </div>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "16px",
              }}
            >
              <Img alt="" fixed={phone} sx={{ mr: "16px" }} />
              <FooterRegularText
                textColor={textColor}
                overrideCSS={{ mb: "0px" }}
              >
                {officeTelephoneNumber}
              </FooterRegularText>
            </div>
          </div>
        )
      })}
      <a
        href={
          langKey === "en"
            ? "https://www.jellyfish.com/en-us/contact-us"
            : "https://www.jellyfish.com/fr-fr/contact-us"
        }
        sx={{
          padding: "10px 20px",
          display: ["none", "none", "inherit", "inherit"],
          textDecoration: "none",
          backgroundColor: "transparent",
          color: colors.code.primary,
          border: `2px solid ${colors.code.primary}`,
          borderRadius: "6px",
          fontSize: "16px",
          width: "fit-content",
          ":hover": {
            cursor: "pointer",
            color: colors.white,
            background: `#F58D00 0% 0% no-repeat`,
          },
        }}
      >
        Contact
      </a>
    </FooterColumn>
  )
}

export default SeelkOffices
