// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const FooterColumn = ({ children }) => (
  <div
    sx={{
      width: "100%",
      minWidth: "211px",
      maxWidth: "350px",
      display: "flex",
      flexDirection: "column",
      "& :last-child": {
        mb: "0",
      },
    }}
  >
    {children}
  </div>
)

export default FooterColumn
