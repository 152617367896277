// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useResponsiveValue } from "@theme-ui/match-media"
import { MOBILE_BAR_HEIGHT, BAR_HEIGHT } from "./constants"
import NavigationBar from "./navigation-bar"
import MobileNavigationPanel from "./mobile-navigation-panel"

const Navigation = ({ langKey, isDarkMode, seelkLogo, seelkDarkLogo }) => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
  const [urlLocation, setUrlLocation] = useState("")

  const closeSidePanel = () => {
    setIsSidePanelOpen(false)
  }

  const openSidePanel = () => {
    setIsSidePanelOpen(true)
  }

  const barHeight = useResponsiveValue(theme => [
    MOBILE_BAR_HEIGHT,
    MOBILE_BAR_HEIGHT,
    BAR_HEIGHT,
    BAR_HEIGHT,
  ])

  useEffect(() => {
    setUrlLocation(window.location.pathname.substring(1))
  })

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar
        isSidePanelOpen={isSidePanelOpen}
        isDarkMode={isDarkMode}
        seelkLogo={seelkLogo}
        seelkDarkLogo={seelkDarkLogo}
        closeSidePanel={closeSidePanel}
        openSidePanel={openSidePanel}
        barHeight={barHeight}
        langKey={langKey}
        urlLocation={urlLocation}
      />
      {isSidePanelOpen && (
        <MobileNavigationPanel
          barHeight={barHeight}
          langKey={langKey}
          urlLocation={urlLocation}
        />
      )}
    </div>
  )
}

export default Navigation
