export const LINKS = [
  {
    to: "/product/",
    contentFR: "Produit",
    contentEN: "Product",
    hasTranslation: true,
  },
  {
    to: "/amazon-agency/",
    contentFR: "Agence",
    contentEN: "Agency",
    hasTranslation: true,
  },
  {
    to: "/success-stories/",
    contentFR: "Success Stories",
    contentEN: "Success Stories",
    hasTranslation: true,
  },
  {
    to: "/blog/",
    contentFR: "Expertise Amazon",
    contentEN: "Amazon Expertise",
    hasTranslation: true,
  },
]

export const MOBILE_BAR_HEIGHT = "4rem"
export const BAR_HEIGHT = "4rem"
