// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import FooterColumn from "./FooterColumn"
import FooterBoldText from "./FooterBoldText"
import FooterNavigationLink from "./FooterNavigationLink"

const Footeritems = ({ textColor, topic, listOfItems, navigateTo }) => (
  <FooterColumn>
    <FooterBoldText textColor={textColor}>{topic}</FooterBoldText>
    {listOfItems.map((item, i) => (
      <FooterNavigationLink
        key={i}
        textColor={textColor}
        itemName={item}
        navigateTo={navigateTo}
      />
    ))}
  </FooterColumn>
)

export default Footeritems
