// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { colors } from "../../utils/theme/colors"

const NavigationLink = ({ navigateTo, content, overrideCSS }) => {
  return (
    <Link
      to={navigateTo}
      sx={{
        color: colors.code.primary,
        backgroundColor: "transparent",
        textDecoration: "none",
        borderRadius: "6px",
        border: "2px solid",
        borderColor: colors.code.primary,
        width: "fit-content",
        padding: "10px 20px",
        ...overrideCSS,
      }}
    >
      {content}
    </Link>
  )
}

export default NavigationLink
