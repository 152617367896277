// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { fontWeights } from "../../../../../utils/theme/font-weights"

const FooterBoldText = ({ textColor, itemName, navigateTo, overrideCSS }) => (
  <Link
    to={navigateTo}
    sx={{
      fontFamily: "Raleway",
      fontSize: "15px",
      fontWeight: fontWeights.normal,
      textDecoration: "none",
      color: textColor,
      width: "fit-content",
      mb: "16px",
      overrideCSS,
    }}
  >
    {itemName}
  </Link>
)

export default FooterBoldText
