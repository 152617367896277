// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { colors } from "../../utils/theme/colors"
import Container from "../../components/layout/container"
import { LINKS } from "./constants"

import { useStaticQuery, graphql } from "gatsby"
import { fontWeights } from "../../utils/theme/font-weights"

const activeStyle = {
  //color: colors.code.primary,
  transition: "width 0.3s eqse 0s",
  paddingBottom: "5px",
  marginBottom: "1.7rem",
  borderBottom: "2px solid #FFA41B",
}

const MobileNavigationPanel = ({ barHeight, langKey, urlLocation }) => {
  const data = useStaticQuery(graphql`
    query MenuMobileQuery {
      FRflag: file(relativePath: { eq: "france.png" }) {
        childImageSharp {
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      UKflag: file(relativePath: { eq: "united-kingdom.png" }) {
        childImageSharp {
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const handleLanguageChange = (urlLocation, goTo) => {
    const hasFrenchUrlPrefix = urlLocation.startsWith("fr")

    if (hasFrenchUrlPrefix && goTo === "en") {
      return `/${urlLocation.slice(3)}`
    } else if (!hasFrenchUrlPrefix && goTo === "fr") {
      return `/fr/${urlLocation}`
    } else return `/${urlLocation}`
  }

  return (
    <div
      sx={{
        backgroundColor: colors.white,
        width: `100%`,
        height: `100vh`,
        position: `fixed`,
        top: `0`,
        left: `0`,
        zIndex: `4`,
        willChange: `transform`,
        transform: `0.5s`,
        paddingTop: barHeight,
        zIndex: 998,
      }}
    >
      <Container
        overrideCSS={{
          height: "100%",
        }}
      >
        <ul
          sx={{
            display: `flex`,
            flexDirection: `column`,
            justifyContent: "center",
            alignItems: `center`,
            margin: 0,
            height: "100%",
          }}
        >
          {LINKS.map(link => (
            <Link
              key={link.to}
              to={
                (langKey === "en" ||
                !link.hasTranslation ||
                langKey === undefined
                  ? ""
                  : `/${langKey}`) + link.to
              }
              activeStyle={activeStyle}
              sx={{
                color: colors.black,
                textDecoration: `none`,
                fontSize: `1rem`,
                fontWeight: `600`,
                marginBottom: `2rem`,
              }}
            >
              {langKey === "en" ? link.contentEN : link.contentFR}
            </Link>
          ))}
          <a
            href={
              langKey === "en"
                ? "https://www.jellyfish.com/en-us/contact-us"
                : "https://www.jellyfish.com/fr-fr/contact-us"
            }
            sx={{
              padding: "5px 20px",
              display: ["inherit", "inherit", "none", "none"],
              textDecoration: "none",
              backgroundColor: "transparent",
              color: colors.code.primary,
              border: `2px solid ${colors.code.primary}`,
              borderRadius: "6px",
              fontSize: "16px",
              ":hover": {
                cursor: "pointer",
                color: colors.white,
                background: `#F58D00 0% 0% no-repeat`,
              },
            }}
          >
            Contact
          </a>
          <div sx={{ display: "flex", mt: "32px" }}>
            <Link
              sx={{
                textDecoration: `none`,
                color: colors.black,
                my: `auto`,
                marginRight: `1rem`,
                fontWeight:
                  langKey === `en` ? fontWeights.bold : fontWeights.normal,
              }}
              to={handleLanguageChange(urlLocation, "en")}
            >
              EN
            </Link>
            <Link
              sx={{
                textDecoration: `none`,
                color: colors.black,
                my: `auto`,
                fontWeight:
                  langKey === `fr` ? fontWeights.bold : fontWeights.normal,
              }}
              to={handleLanguageChange(urlLocation, "fr")}
            >
              FR
            </Link>
          </div>
        </ul>
      </Container>
    </div>
  )
}

export default MobileNavigationPanel
