// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { colors } from "../../utils/theme/colors"
import Container from "./container"
import { FaTimes, FaBars } from "react-icons/fa"
import Img from "gatsby-image"
import { LINKS } from "./constants"
import { fontWeights } from "../../utils/theme/font-weights"

const MobileNavigationBar = ({
  isDarkMode,
  barHeight,
  seelkLogo,
  seelkDarkLogo,
  isSidePanelOpen,
  closeSidePanel,
  openSidePanel,
  langKey,
  urlLocation,
}) => {
  const [isSolid, setIsSolid] = useState(false)

  const textColors = isDarkMode ? colors.white : colors.code.secondary

  const activeStyle = {
    borderBottom: "2px solid",
    borderColor: colors.code.primary,
    transition: "width 0.3s eqse 0s",
    lineHeight: "30px",
  }

  /* Breakpoint position in px from which we solidify the navbar. */
  const SOLIDIFY_BREAKPOINT = 20

  const solidifyNavbar = () => {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    const newIsSolid = scrollPosition > SOLIDIFY_BREAKPOINT

    /* Only update state if necessary to avoid useless rendering. */
    if (isSolid !== newIsSolid) {
      setIsSolid(newIsSolid)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", solidifyNavbar, { passive: true })
    return () => window.removeEventListener("scroll", solidifyNavbar)
  })

  const handleLanguageChange = (urlLocation, goTo) => {
    const hasFrenchUrlPrefix = urlLocation.startsWith("fr")

    if (hasFrenchUrlPrefix && goTo === "en") {
      return `/${urlLocation.slice(3)}`
    } else if (!hasFrenchUrlPrefix && goTo === "fr") {
      return `/fr/${urlLocation}`
    } else return `/${urlLocation}`
  }

  return (
    <div
      sx={{
        backgroundColor: isSidePanelOpen
          ? colors.white
          : isSolid
          ? isDarkMode
            ? colors.code.secondary
            : colors.white
          : `none`,
        position: `fixed`,
        width: `100%`,
        height: barHeight,
        boxShadow: isSolid && `0 4px 6px 0 rgba(29, 48, 111, 0.09)`,
        transition: `background-color 0.2s ease-in-out`,
        zIndex: 999,
      }}
    >
      <Container
        overrideCSS={{
          width: `100%`,
          maxWidth: "100%",
          height: "100%",
          display: `flex`,
          alignItems: `center`,
          justifyContent: ["space-between", `space-between`, null, null],
        }}
      >
        <Link
          to={`/`}
          sx={{
            "& > div": {
              verticalAlign: "middle",
            },
          }}
        >
          <Img
            alt=""
            fluid={isSidePanelOpen ? seelkDarkLogo : seelkLogo}
            sx={{ width: "230px" }}
          />
        </Link>
        <div
          sx={{
            display: ["none", "none", "flex", "flex"],
            alignItems: "center",
            justifyContent: "space-between",
            mx: "auto",
          }}
        >
          {LINKS.map(link => (
            <Link
              key={link.to}
              to={
                (langKey === "en" ||
                !link.hasTranslation ||
                langKey === undefined
                  ? ""
                  : `/${langKey}`) + link.to
              }
              activeStyle={activeStyle}
              sx={{
                color:
                  `/${urlLocation}` === link.to.slice(0, -1)
                    ? colors.code.primary
                    : textColors,
                textDecoration: `none`,
                fontSize: `16px`,
                fontWeight: `600`,
                alignSelf: `center`,
                lineHeight: `30px`,
                marginRight: `32px`,
                borderBottom: `2px solid`,
                borderColor:
                  `/${urlLocation}` === link.to.slice(0, -1)
                    ? colors.code.primary
                    : "transparent",
                ":hover": {
                  borderBottom: `2px solid`,
                  borderColor: colors.code.primary,
                },
              }}
            >
              {langKey === "en" ? link.contentEN : link.contentFR}
            </Link>
          ))}
        </div>
        <a
          href={
            langKey === "en"
              ? "https://www.jellyfish.com/en-us/contact-us"
              : "https://www.jellyfish.com/fr-fr/contact-us"
          }
          sx={{
            mr: "32px",
            padding: "5px 20px",
            display: ["none", "none", "inherit", "inherit"],
            textDecoration: "none",
            backgroundColor: "transparent",
            color: colors.code.primary,
            border: `2px solid ${colors.code.primary}`,
            borderRadius: "6px",
            fontSize: "16px",
            ":hover": {
              cursor: "pointer",
              color: colors.white,
              background: `#F58D00 0% 0% no-repeat`,
            },
          }}
        >
          Contact
        </a>
        <div sx={{ display: [`none`, `none`, `flex`, `flex`] }}>
          <Link
            sx={{
              textDecoration: `none`,
              color: textColors,
              my: `auto`,
              marginRight: `1rem`,
              fontWeight:
                langKey === `en` ? fontWeights.bold : fontWeights.normal,
            }}
            to={handleLanguageChange(urlLocation, "en")}
          >
            EN
          </Link>
          <Link
            sx={{
              textDecoration: `none`,
              color: textColors,
              my: `auto`,
              fontWeight:
                langKey === `fr` ? fontWeights.bold : fontWeights.normal,
            }}
            to={handleLanguageChange(urlLocation, "fr")}
          >
            FR
          </Link>
        </div>

        {isSidePanelOpen ? (
          <FaTimes
            sx={{
              color: colors.black,
              display: [null, null, "none", "none"],
            }}
            onClick={closeSidePanel}
          />
        ) : (
          <FaBars
            sx={{
              color: isDarkMode ? colors.white : colors.black,
              display: [null, null, "none", "none"],
            }}
            onClick={openSidePanel}
          />
        )}
      </Container>
    </div>
  )
}

export default MobileNavigationBar
