// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../../../../utils/theme/font-weights"

const FooterBoldText = ({ textColor, children, overrideCSS }) => (
  <p
    sx={{
      fontFamily: "Raleway",
      fontSize: "15px",
      fontWeight: fontWeights.normal,
      color: textColor,
      mb: "16px",
      ...overrideCSS,
    }}
  >
    {children}
  </p>
)

export default FooterBoldText
