// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "../container"
import SeelkOffices from "./components/SeelkOffices"
import FooterItems from "./components/shared/FooterItems"
import FooterRegularText from "./components/shared/FooterRegularText"
import { colors } from "../../../utils/theme/colors"

const Footer = ({ langKey, seelkDarkLogo, allContentfulFooter }) => {
  const data = useStaticQuery(graphql`
    query LinkedInIconQUery {
      linkedIn: file(relativePath: { eq: "LinkedInIcon.png" }) {
        childImageSharp {
          fixed(width: 18) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const {
    seelkOffices,
    saasForAmazon,
    agencyServices,
    aboutSeelk,
    contact,
    connect,
    copyrights,
  } = allContentfulFooter.edges[0].node

  const textColor = colors.code.secondary

  return (
    <div
      sx={{
        backgroundColor: colors.code.white,
      }}
    >
      <Container
        overrideCSS={{
          pt: "64px",
          pb: "16px",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: "64px",
            pb: "16px",
            borderTop: "2px solid #F5F9FF",
          }}
        >
          <Img
            alt=""
            fluid={seelkDarkLogo}
            sx={{ width: "230px", mb: "32px" }}
          />
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: [
                "1fr",
                "1fr 1fr",
                "1fr 1fr 1fr 1fr",
                "1fr 1fr 1fr 1fr",
              ],
              gap: ["64px", "64px", "5%"],
              mb: "64px",
            }}
          >
            <SeelkOffices
              textColor={textColor}
              seelkOffices={seelkOffices}
              langKey={langKey}
              contact={contact}
            />
            <FooterItems
              textColor={textColor}
              topic={"Saas for Amazon"}
              listOfItems={saasForAmazon}
              navigateTo={
                langKey === "fr"
                  ? "/fr/amazon-analytics-software"
                  : "/amazon-analytics-software"
              }
            />
            <FooterItems
              textColor={textColor}
              topic={"Our Agency services"}
              listOfItems={agencyServices}
              navigateTo={
                langKey === "fr"
                  ? "/fr/amazon-analytics-software"
                  : "/amazon-analytics-software"
              }
            />
            <FooterItems
              textColor={textColor}
              topic={"About Seelk"}
              listOfItems={aboutSeelk}
              navigateTo={
                langKey === "fr" ? `/fr/amazon-agency` : `/amazon-agency`
              }
            />
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row", "row", "row"],
              justifyContent: "space-between",
            }}
          >
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FooterRegularText textColor={textColor}>
                {connect}
              </FooterRegularText>
              <a
                href={"https://www.linkedin.com/company/seelk/"}
                target="_blank"
                sx={{ ml: "16px" }}
              >
                <Img
                  alt="LinkedIn"
                  fixed={data.linkedIn.childImageSharp.fixed}
                />
              </a>
            </div>
            <FooterRegularText textColor={textColor}>
              {copyrights}
            </FooterRegularText>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
