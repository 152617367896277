/** @jsx jsx */
import { jsx } from "theme-ui"
import { breakpoints } from "../../utils/theme/breakpoints"

const Container = ({ children, withSidebar = true, overrideCSS }) => (
  <div
    sx={{
      maxWidth: [breakpoints.sm, breakpoints.lg, breakpoints.xl],
      mx: "auto",
      position: "relative",
      px: ["1.875rem", "5.25rem", "5.5rem", "12.88rem"],
      width: "100%",
      ...overrideCSS,
    }}
  >
    {children}
  </div>
)

export default Container
